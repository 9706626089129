import { appAbility } from '~/authorization/ability'

export default defineNuxtRouteMiddleware(async () => {
  const userStore = useUserStore()
  const { hasUser } = storeToRefs(userStore)

  await userStore.fetchUser()

  useNuxtApp().vueApp.config.globalProperties.$ability.update(appAbility(userStore.user).rules) // TODO: find a better way

  const config = useRuntimeConfig()
  if (config.public.disableAuth === 'true')
    return

  const url = useRequestURL()

  if (!hasUser.value)
    return navigateToLogin(config.public.loginRoute, url)
})
