import { isValid, parse, parseISO } from 'date-fns'

function isIsoDateString(value: string | unknown): boolean {
  return !!(value && typeof value === 'string' && isValidDateObject(parseISO(value)))
}

function isSimpleDate(value: string | unknown): boolean {
  return !!(value && typeof value === 'string' && isValidDateObject(parse(value, 'yyyy-MM-dd', new Date())))
}

function isValidDateObject(date: unknown): date is Date {
  return isValid(date)
}

export function handleResponseDates(body: unknown) {
  if (isObject(body) && body !== null && body !== undefined) {
    Object.entries(body).forEach(([key, value]) => {
      if (isObject(value))
        handleResponseDates(value)
      else if (key.endsWith('At') && (isIsoDateString(value) || isSimpleDate(value)))
        (body as Record<string, unknown>)[key] = new Date(value)
    })
  }
}

export function handleRequestDates(body: unknown) {
  if (isObject(body) && body !== null && body !== undefined) {
    Object.entries(body).forEach(([key, value]) => {
      if (key.endsWith('At') && isValidDateObject(value))
        (body as Record<string, unknown>)[key] = value.toISOString()

      else if (isObject(value))
        handleRequestDates(value)
    })
  }
}
