import { stringify as qsStringify } from 'qs'

export function serializeQuery(query: Record<string, any>) {
  const queryString = qsStringify(query, { encode: false })

  return queryString.split('&').reduce<Record<string, string>>((acc, part) => {
    const [key, value] = part.split('=')

    acc[key] = value

    return acc
  }, {})
}
