import { objectToCamel, objectToSnake, toSnake } from 'ts-case-convert'
import { navigateToLogin } from '~/utils/externalRouting'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const requestURL = useRequestURL()

  const message = useMessageStore()

  const $api = $fetch.create({
    baseURL: config.public.apiBase ?? '',
    // this overrides the default key generation, which includes a hash of
    // url, method, headers, etc. - this should be used with care as the key
    // is how Nuxt decides how responses should be deduplicated between
    // client and server

    // set user token if connected
    // headers: userAuth.value
    //   ? {Authorization: `Bearer ${userAuth.value}`}
    //   : {},
    headers: {
      ...useRequestHeaders(['referer', 'cookie']),
      accept: 'application/json',
      origin: requestURL.origin,
    },

    credentials: 'include',

    onResponse({ response }) {
      if (isObject(response._data) && response.headers.get('content-type') === 'application/json')
        response._data = objectToCamel(response._data)

      // convert incoming date strings to dates
      handleResponseDates(response._data)
    },

    onRequest({ options }) {
      // convert outgoing dates to iso strings
      handleRequestDates(options?.body)
      handleRequestDates(options?.query)
      handleRequestDates(options?.params)

      // handle request params arrays

      if (isObject(options?.body))
        options.body = objectToSnake(options.body)
      if (isObject(options?.query)) {
        options.query = objectToSnake(options.query)
        options.query = serializeQuery(options.query)
      }
      if (isObject(options?.params)) {
        options.params = objectToSnake(options.params)
        options.params = serializeQuery(options.params)
      }

      // snake_case sort value if needed (pagination)
      if (isString(options?.params?.sort))
        options.params.sort = toSnake(options.params.sort)
      if (isString(options?.query?.sort))
        options.query.sort = toSnake(options.query.sort)
    },

    async onResponseError(ctx) {
      if (ctx.response.status === 401) {
        await navigateToLogin(config.public.loginRoute, requestURL)
      }
      else if (ctx.response.status === 429) {
        if (import.meta.browser) {
          message.createMessage('Zu viele Anfragen! Bitte warte einen Augenblick.', {
            type: 'error',
          })
        }
        else {
          throw createError({
            statusCode: 429,
            statusMessage: 'Too many requests',
          })
        }
      }
    },
  })
  return {
    provide: {
      api: $api,
    },
  }
})
