import type { ModuleOptions } from '@bg-dev/nuxt-naiveui'

export const themeOverrides: ModuleOptions['themeConfig'] = {
  shared: {
    common: {
      primaryColor: '#EF7D29',
      primaryColorHover: '#F29654',
      primaryColorPressed: '#E84E0F',
      primaryColorSuppl: '#BB3E0C',
    },
    Form: {
      feedbackPadding: '5px 0 0 0',
    },
    Tabs: {
      panePaddingMedium: '2rem 2.5rem',
      tabPaddingMediumBar: '10px 0.25rem',
    },
    Collapse: {
      titleFontSize: '18px',

      titleFontWeight: '600',
    },
    Dialog: {
      contentMargin: '16px 0',
    },
  },
}
