import type { MessageOptions } from 'naive-ui'

export const useMessageStore = defineStore('message', () => {
  const message = ref<{
    message: string
    options: MessageOptions
  }>()

  function createMessage(msg: string, options: MessageOptions) {
    message.value = { message: msg, options }
  }

  return {
    createMessage,
    message,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useMessageStore, import.meta.hot))
