import * as Sentry from '@sentry/nuxt'

export const useUserStore = defineStore('user', () => {
  const { data: _user, execute } = useApiAuthGetUser({ immediate: false })
  const fetchUser = async () => {
    return await execute()
  }
  const user = computed(() => _user.value?.data)
  const hasUser = computed(() => !!user.value?.id)

  watch(user, (newUser) => {
    if (newUser) {
      Sentry.setUser({
        id: newUser.id,
        username: newUser.name,
        email: newUser.email,
      })
    }
  })

  return {
    hasUser,
    user,
    fetchUser,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
