import type { UseFetchOptions } from '#app'
import { defu } from 'defu'
import type { ApiResponse, UseApiFetchOptions } from '~/types/api.type'
import type { User } from '~/types/user.type'

export function useApiAuthGetUser(options: UseApiFetchOptions<ApiResponse<User>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<User>> = { method: 'GET' }
  return useApiFetch('/auth/user', defu(notOverridableOptions, options))
}

export function useApiAuthPostLogout(options: UseFetchOptions<{ message: string }> = {}) {
  const notOverridableOptions: UseFetchOptions<{ message: string }> = { method: 'POST', immediate: false, watch: false, onResponseError: () => ({}) }
  return useApiFetch('/auth/logout', defu(notOverridableOptions, options))
}
